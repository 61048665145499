// src/components/common/Sidebar.tsx
import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface SidebarProps {
  isOpen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen }) => {
  const location = useLocation();
  console.log(isOpen)

  const menuItems = [
    { path: '/', label: 'Dashboard', icon: '📊' },
    { path: '/documents', label: 'Documents', icon: '📄' },
    { path: '/extraction', label: 'Extraction', icon: '🔍' },
    { path: '/processing', label: 'Processing', icon: '⚙️' },
    { path: '/analytics', label: 'Analytics', icon: '📈' },
    { path: '/users', label: 'User Management', icon: '👥' },
    { path: '/subscriptions', label: 'Subscriptions', icon: '🔄' },
    { path: '/revenue', label: 'Revenue', icon: '💰' },
    { path: '/api-keys', label: 'API Keys', icon: '🔑' },
    { path: '/webhooks', label: 'Webhooks', icon: '🔗' },
    { path: '/monitoring', label: 'System Health', icon: '🖥️' },
    { path: '/logs', label: 'Logs', icon: '📝' },
    { path: '/settings', label: 'Settings', icon: '⚙️' },
  ];

  useEffect(() => {
    console.log('Sidebar is open:', isOpen)
  }, [isOpen])

  return (
    <aside className='sidebarAside'>
        {isOpen ? (
            <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
                <ul>
                {menuItems.map((item) => (
                    <li key={item.path}>
                    <NavLink to={item.path} className={location.pathname === item.path ? 'active' : ''}>
                        <span className="icon">{item.icon}</span>
                        <span className="label">{item.label}</span>
                    </NavLink>
                    </li>
                ))}
                </ul>
            </nav>
        ) : null}

    </aside>
  );
};

export default Sidebar;