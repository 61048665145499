// src/pages/HomePage.tsx
import React from 'react';
import { Link } from 'react-router-dom';

export default function HomePage(){
  return (
    <div className="home-page">
      <h1>Document Helper Dashboard</h1>
      <div className="dashboard-summary">
        <div className="summary-card">
          <h2>Documents</h2>
          <p>Total: 100</p>
          <p>Processed: 75</p>
          <Link to="/documents">View Documents</Link>
        </div>
        <div className="summary-card">
          <h2>Extraction Jobs</h2>
          <p>Pending: 5</p>
          <p>Completed: 70</p>
          <Link to="/jobs">View Jobs</Link>
        </div>
        <div className="summary-card">
          <h2>System Health</h2>
          <p>Status: Good</p>
          <p>Last Error: None</p>
          <Link to="/monitoring">View System Health</Link>
        </div>
      </div>
    </div>
  );
};