// src/components/common/Header.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebase';

interface HeaderProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ 
  toggleSidebar, isSidebarOpen
}) => {

  const { user } = useAuth();

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };


  return (
    <header className="app-header">
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        {isSidebarOpen ? '❮' : '❯'}

      </button>
      <Link to="/" className="logo">
        Seriph Admin Panel
      </Link>
      <nav>
        <input type="search" placeholder="Search..." />
        <button className="create-new">+ CREATE NEW</button>
        <div className="user-menu">👤</div>
        {user && (
          <>
            <span>Welcome, {user.email}</span>
            <button onClick={handleLogout}>Logout</button>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
