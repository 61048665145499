// src/pages/SubscriptionsPage.tsx
import React from 'react';

const SubscriptionsPage: React.FC = () => {
  return (
    <div className="subscriptions-page">
      <h1>Subscriptions</h1>
      {/* Add subscription management content here */}
    </div>
  );
};
export default SubscriptionsPage;
