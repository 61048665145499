// src/App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/common/Header';
import Sidebar from './components/common/Sidebar';

import HomePage from './pages/HomePage';
import SubscriptionsPage from './pages/SubscriptionsPage';
import RevenuePage from './pages/RevenuePage';
import LoginPage from './pages/LoginPage';

import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/admin/PrivateRoute';




function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };



  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <Header 
                    toggleSidebar={toggleSidebar} 
                    isSidebarOpen={isSidebarOpen}
                  />
                  <Sidebar isOpen={isSidebarOpen} />
                  <main className="main-content">
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/subscriptions" element={<SubscriptionsPage />} />
                      <Route path="/revenue" element={<RevenuePage />} />
                    </Routes>
                  </main>
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;

